<template>
  <Layout>
    <section class="csms-plan">

      <!-- Page Title Start -->
      <PageHeader :title="title" />
      <!-- Page Title End -->

      <!-- Plan List Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <!-- // List -->
              <div class="table-responsive">
                <b-table class="text-nowrap align-middle" bordered hover
                         :fields="fields" :items="items">
                  <template #head()="data">
                    <span v-if="!$_utils.isEmpty(data.label)">{{ $t(`plan.${data.column}`) }}</span>
                  </template>

                  <template #cell(num)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(planDescription)="data">
                    {{ $_utils.textLengthOverCut(data.item.planDescription, 40, null) }}
                  </template>
                  <template #cell(serverUsageFee)="data">
                    {{ !$_utils.isEmpty(data.item.serverUsageFee) ? $_utils.comma(data.item.serverUsageFee) : '-' }}
                  </template>
                  <template #cell(profitDistRate)="data">
                    {{ data.item.id === 10000000000225 ? data.item.profitDistRate : '-' }}
                  </template>
                  <template #cell(etc)="data">
                    <b-button size="sm" variant="warning" @click="edit(data.item.id)">
                      <i class="uil uil-edit me-2"></i>
                      <span>{{ $t('btn.edit') }}</span>
                    </b-button>
                  </template>
                </b-table>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Plan List End -->

      <hr class="mb-4" />

      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="ms-2 mb-0 font-size-18 ion-text-primary">{{ $t('plan.planJoinStatus') }}</h4>
          </div>
        </div>
      </div>

      <b-tabs content-class="p-3 text-muted">
        <b-tab active class="border-0" @click="tab = 'HQ'">
          <template v-slot:title>{{ $t('common.headquarters') }}</template>

          <!-- Search Area Start -->
          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body">

                  <div class="row">
                    <!-- // Search Input -->
                    <div class="col-xl-10 ion-mb-mb-100">
                      <div class="row">
                        <div class="col-xl-3">
                          <label class="form-label" for="plan-search-name">{{ $t('plan.planName') }}</label>
                          <multiselect id="plan-search-name"
                                       label="text" track-by="value"
                                       v-model="searchHqForm.plan"
                                       :options="planOptions"
                                       selectLabel=""
                                       selectedLabel=""
                                       deselectLabel=""
                                       :searchable="false" :allow-empty="false" />
                        </div>
                      </div>
                    </div>
                    <!-- // Search Button -->
                    <div class="col-xl-2 align-content-end">
                      <div class="float-end">
                        <b-button variant="secondary" class="me-3" @click="reset">
                          <i class="uil uil-redo me-2"></i>
                          <span>{{ $t('btn.reset') }}</span>
                        </b-button>
                        <b-button variant="primary" @click="search">
                          <i class="uil uil-search me-2"></i>
                          <span>{{ $t('btn.search') }}</span>
                        </b-button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <!-- Search Area End -->

          <!-- HQ Plan Join List Start -->
          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body">

                  <div class="row mb-3 align-items-end">
                    <!-- // List PerPage Count -->
                    <div class="col-xl-auto">
                      <PerPageSelector v-model="hqPagination.perPage"
                                       :option="[10, 25, 50]"
                                       @change="onPerPageChange" />
                    </div>
                    <!-- // Total Count -->
                    <div class="col me-2">
                      <div class="float-end">
                        <total-count :total-count="hqPagination.totalCount" />
                      </div>
                    </div>
                  </div>

                  <!-- // List -->
                  <div class="table-responsive">
                    <b-table class="text-nowrap align-middle" bordered hover
                             show-empty :empty-text="$t('plan.noHq')"
                             :fields="hgFields" :items="hqItems">
                      <template #head()="data">
                        <span v-if="!$_utils.isEmpty(data.label)">{{ $t(`plan.${data.column}`) }}</span>
                      </template>

                      <template #cell(planName)="data">
                        <span>{{ !$_utils.isEmpty(data.item.planName) ? data.item.planName : '-' }}</span>
                      </template>
                      <template #cell(serverUsageFee)="data">
                        {{ !$_utils.isEmpty(data.item.serverUsageFee) ? $_utils.comma(data.item.serverUsageFee) : '-' }}
                      </template>
                      <template #cell(settleRate)="data">
                        {{ !$_utils.isEmpty(data.item.settleRate) ? data.item.settleRate : '-' }}
                      </template>
                      <template #cell(profitDistRate)="data">
                        <span>{{ data.item.commissionRate ? data.item.commissionRate : '-' }}</span>
                      </template>
                      <template #cell(planDate)="data">
                        <span>{{ data.item.planDate && !$_utils.isEmpty(data.item.planDate) ? data.item.planDate : '-' }}</span>
                      </template>
                      <template #cell(planEditDate)="data">
                        <span>{{ !$_utils.isEmpty(data.item.settleInfoUpdateTimestamp) ? $_formatter.timestampToDate(data.item.settleInfoUpdateTimestamp) : '-' }}</span>
                      </template>
                    </b-table>
                  </div>

                  <!-- // Pagination -->
                  <div class="row mt-3">
                    <div class="col-xl-12">
                      <b-pagination size="sm" class="mb-0" align="center"
                                    :total-rows="hqPagination.totalCount"
                                    :per-page="hqPagination.perPage"
                                    v-model="hqPagination.currentPage"
                                    @change="onPageChangeForHq" />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <!-- Plan Join List End -->
        </b-tab>
        <b-tab @click="tab = 'CP'">
          <template v-slot:title>{{ $t('common.cp') }}</template>
          <!-- Search Area Start -->
          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body">

                  <div class="row">
                    <!-- // Search Input -->
                    <div class="col-xl-10 ion-mb-mb-100">
                      <div class="row">
                        <div class="col-xl-3">
                          <label class="form-label" for="plan-search-name">{{ $t('plan.planName') }}</label>
                          <multiselect id="plan-search-name"
                                       label="text" track-by="value"
                                       v-model="searchForm.plan"
                                       :options="planOptions"
                                       selectLabel=""
                                       selectedLabel=""
                                       deselectLabel=""
                                       :searchable="false" :allow-empty="false" />
                        </div>
                        <div class="col-xl-3">
                          <label class="form-label" for="plan-search-hq">{{ $t('common.headquarters') }}</label>
                          <multiselect id="plan-search-hq"
                                       v-model="searchForm.hqId"
                                       label="text" track-by="value"
                                       :options="hqOptions"
                                       selectLabel=""
                                       selectedLabel=""
                                       deselectLabel=""
                                       :searchable="false" :allow-empty="false" />
                        </div>
                      </div>
                    </div>
                    <!-- // Search Button -->
                    <div class="col-xl-2 align-content-end">
                      <div class="float-end">
                        <b-button variant="secondary" class="me-3" @click="reset">
                          <i class="uil uil-redo me-2"></i>
                          <span>{{ $t('btn.reset') }}</span>
                        </b-button>
                        <b-button variant="primary" @click="search">
                          <i class="uil uil-search me-2"></i>
                          <span>{{ $t('btn.search') }}</span>
                        </b-button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <!-- Search Area End -->

          <!-- Plan Join List Start -->
          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body">

                  <div class="row mb-3 align-items-end">
                    <!-- // List PerPage Count -->
                    <div class="col-xl-auto">
                      <PerPageSelector v-model="pagination.perPage"
                                       :option="[10, 25, 50]"
                                       @change="onPerPageChange" />
                    </div>
                    <!-- // Total Count -->
                    <div class="col me-2">
                      <div class="float-end">
                        <total-count :total-count="pagination.totalCount" />
                      </div>
                    </div>
                  </div>

                  <!-- // List -->
                  <div class="table-responsive">
                    <b-table class="text-nowrap align-middle" bordered hover
                             show-empty :empty-text="$t('plan.noCp')"
                             :fields="cpFields" :items="cpItems">
                      <template #head()="data">
                        <span v-if="!$_utils.isEmpty(data.label)">{{ $t(`plan.${data.column}`) }}</span>
                      </template>

                      <template #cell(planName)="data">
                        <span>{{ data.item.chargePlacePlan ? data.item.chargePlacePlan.planName : '-' }}</span>
                      </template>
                      <template #cell(hqName)="data">
                        <span>{{ data.item.hqBusiness ? data.item.hqBusiness.hqName : '-' }}</span>
                      </template>
                      <template #cell(serverUsageFee)="data">
                        {{ !$_utils.isEmpty(data.item.serverUsageFee) ? $_utils.comma(data.item.serverUsageFee) : '-' }}
                      </template>
                      <template #cell(settleRate)="data">
                        {{ !$_utils.isEmpty(data.item.settleRate) ? data.item.settleRate : '-' }}
                      </template>
                      <template #cell(profitDistRate)="data">
                        <span>{{ data.item.commissionRate ? data.item.commissionRate : '-' }}</span>
                      </template>
                      <template #cell(planDate)="data">
                        <span>{{ data.item.planDate && !$_utils.isEmpty(data.item.planDate) ? data.item.planDate : '-' }}</span>
                      </template>
                      <template #cell(planEditDate)="data">
                        <span>{{ !$_utils.isEmpty(data.item.settleInfoUpdateTimestamp) ? $_formatter.timestampToDate(data.item.settleInfoUpdateTimestamp) : '-' }}</span>
                      </template>
                    </b-table>
                  </div>

                  <!-- // Pagination -->
                  <div class="row mt-3">
                    <div class="col-xl-12">
                      <b-pagination size="sm" class="mb-0" align="center"
                                    :total-rows="pagination.totalCount"
                                    :per-page="pagination.perPage"
                                    v-model="pagination.currentPage"
                                    @change="onPageChange" />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <!-- Plan Join List End -->
        </b-tab>
      </b-tabs>

      <edit-modal ref="businessPlanEditModal" :selected-id="selectedId" @callback="getItems" />
    </section>
  </Layout>
</template>

<script>
import { http } from '@/auth-api';
import { loadingMethods } from '@/state/helpers';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import Layout from '@/views/layouts/Main.vue';
import PageHeader from '@/components/PageHeader.vue';
import PerPageSelector from '@/views/components/PerPageSelector.vue';
import TotalCount from '@/views/components/TotalCount.vue';
import EditModal from '@/views/pages/plan/BusinessPlanEditModal.vue';

export default {
  components: {
    Multiselect, Layout, PageHeader, PerPageSelector, TotalCount, EditModal
  },
  data() {
    return {
      title: 'businessPlan',
      planOptions: [
        { 'text': i18n.t('common.total'), 'value': null },
        { 'text': i18n.t('common.zeroPlan'), 'value': 10000000000225 },
        { 'text': i18n.t('common.buyPlan'), 'value': 10000000000224 }
      ],
      selectedId: null,
      searchConditions: [],
      searchHqConditions: [],
      sortDirection: 'DESC',
      planPagination: {
        currentPage: 1,
        totalCount : 0,
        perPage: 10
      },
      fields: [
        { key: 'num', label: 'No.', class: 'text-center' },
        { key: 'planName', label: '플랜명', class: 'text-center' },
        { key: 'planDescription', label: '플랜 설명', class: 'text-center' },
        { key: 'serverUsageFee', label: '서버 이용료', class: 'text-center' },
        { key: 'settleRate', label: '정산 수수료', class: 'text-center' },
        { key: 'profitDistRate', label: '수익 배분', class: 'text-center' },
        { key: 'updated', label: '마지막 수정일', class: 'text-center' }
      ],
      items: null,
      hqPagination: {
        currentPage: 1,
        totalCount : 0,
        perPage: 10
      },
      hgFields: [
        { key: 'rnum', label: 'No.', class: 'text-center' },
        { key: 'planName', label: '플랜명', class: 'text-center' },
        { key: 'hqName', label: '사업 본부명', class: 'text-center' },
        { key: 'serverUsageFee', label: '서버 이용료', class: 'text-center' },
        { key: 'settleRate', label: '정산 수수료', class: 'text-center' },
        { key: 'profitDistRate', label: '수익 배분', class: 'text-center' },
        { key: 'planDate', label: '플랜 시작일', class: 'text-center' },
        { key: 'planEditDate', label: '플랜 수정일', class: 'text-center' }
      ],
      hqItems: null,
      pagination: {
        currentPage: 1,
        totalCount : 0,
        perPage: 10
      },
      cpFields: [
        { key: 'rnum', label: 'No.', class: 'text-center' },
        { key: 'planName', label: '플랜명', class: 'text-center' },
        { key: 'hqName', label: '사업 본부명', class: 'text-center' },
        { key: 'placeName', label: '충전소명', class: 'text-center' },
        { key: 'serverUsageFee', label: '서버 이용료', class: 'text-center' },
        { key: 'settleRate', label: '정산 수수료', class: 'text-center' },
        { key: 'profitDistRate', label: '수익 배분', class: 'text-center' },
        { key: 'planDate', label: '플랜 시작일', class: 'text-center' },
        { key: 'planEditDate', label: '플랜 수정일', class: 'text-center' }
      ],
      cpItems: null,
      hqOptions: [],
      searchForm: {
        plan: { text: i18n.t('common.total'), value: null },
        hqId: { text: i18n.t('common.total'), value: null },
      },
      searchHqForm: {
        plan: { text: i18n.t('common.total'), value: null }
      },
      tab: 'HQ'
    }
  },
  created() {
    const vm = this;

    if (this.$_app.user.roles[0] === 'ADMIN') {
      vm.fields.push({ key: 'etc', label: '', class: 'text-center' });
    }
  },
  mounted() {
    const vm = this;

    vm.getHqOptions();
    vm.getItems();
    vm.getHqItems();
    vm.getCpItems();
  },
  methods: {
    ...loadingMethods,
    getHqOptions() {
      const vm = this;
      const _url = '/onmapi/view/Hq/listAll';

      http.get(_url, {}).then(res => {
        vm.hqOptions = [{ text: i18n.t('common.total'), value: null }];

        res.data.items.forEach(item => {
          vm.hqOptions.push({text: item.hqName, value: item.id});
        });
      }).catch(err => {
        console.error('Headquarters List Data Error :: ', err)
      });
    },
    getItems() {
      const vm = this;
      const _url = '/onmapi/view/ChargePlacePlan/list';

      http.post(_url, {
        page: vm.planPagination.currentPage,
        limit: vm.planPagination.perPage,
        searchConditions: [],
        sortCondition: { sortDirection: vm.sortDirection }
      }).then(res => {
        vm.items = res.data.items;
        vm.planPagination.totalCount = res.data.totalCount;
      }).catch(err => {
        console.error('Business Plan List Error :: ', err)
      });
    },
    reset() {
      const vm = this;

      if (vm.tab === 'HQ') {
        vm.searchHqForm = {
          plan: { text: i18n.t('common.total'), value: null }
        };

        vm.searchHqConditions = [];
        vm.getHqItems();
      } else {
        vm.searchForm = {
          plan: { text: i18n.t('common.total'), value: null },
          hqId: { text: i18n.t('common.total'), value: null },
        };

        vm.searchConditions = [];
        vm.getCpItems();
      }
    },
    search() {
      const vm = this;

      if (vm.tab === 'HQ') {
        vm.searchHqConditions = [];

        if (!this.$_utils.isEmpty(vm.searchHqForm.plan.value)) {
          vm.searchHqConditions.push({ searchKey: 'planId', searchValue: vm.searchHqForm.plan.value });
        }

        vm.hqPagination.currentPage = 1;
        vm.getHqItems();
      } else {
        vm.searchConditions = [];

        if (!this.$_utils.isEmpty(vm.searchForm.hqId.value)) {
          vm.searchConditions.push({ searchKey: 'hqId', searchValue: vm.searchForm.hqId.value });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.plan.value)) {
          vm.searchConditions.push({ searchKey: 'planId', searchValue: vm.searchForm.plan.value });
        }

        vm.pagination.currentPage = 1;
        vm.getCpItems();
      }
    },
    async getHqItems() {
      const vm = this;
      const _url = '/onmapi/view/Hq/list';

      vm.showOverlay();

      await http.post(_url, {
        page: vm.hqPagination.currentPage,
        limit: vm.hqPagination.perPage,
        searchConditions: vm.searchHqConditions,
        sortCondition: { sortDirection: vm.sortDirection }
      }).then(res => {
        vm.hqItems = res.data.items;
        vm.hqPagination.totalCount = res.data.totalCount;
        vm.hideOverlay();
      }).catch(err => {
        console.error('Business Plan Hq List Error :: ', err)
        vm.hideOverlay();
      });
    },
    async getCpItems() {
      const vm = this;
      const _url = '/onmapi/view/ChargePlace/list';

      vm.showOverlay();

      await http.post(_url, {
        page: vm.pagination.currentPage,
        limit: vm.pagination.perPage,
        searchConditions: vm.searchConditions,
        sortCondition: { sortDirection: vm.sortDirection }
      }).then(res => {
        vm.cpItems = res.data.items;
        vm.pagination.totalCount = res.data.totalCount;
        vm.hideOverlay();
      }).catch(err => {
        console.error('Business Plan Charge Place List Error :: ', err)
        vm.hideOverlay();
      });
    },
    edit(id) {
      const vm = this;

      vm.selectedId = id;

      vm.$nextTick(() => {
        this.$refs.businessPlanEditModal.show();
      });
    },
    async onPerPageChange() {
      const vm = this;
      if (vm.tab === 'HQ') {
        if (vm.hqPagination.totalCount > 1) {
          vm.hqPagination.currentPage = 1;
          await vm.getHqItems();
        }
      } else {
        if (vm.pagination.totalCount > 1) {
          vm.pagination.currentPage = 1;
          await vm.getCpItems();
        }
      }
    },
    async onPageChange(val) {
      const vm = this;
      vm.pagination.currentPage = val;

      if (vm.pagination.totalCount > 1) {
        await vm.getCpItems();
      }
    },
    async onPageChangeForHq(val) {
      const vm = this;
      vm.hqPagination.currentPage = val;

      if (vm.hqPagination.totalCount > 1) {
        await vm.getHqItems();
      }
    }
  }
}
</script>

<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>