<template>
  <b-modal header-class="ion-bg-modal-header" size="xl"
           centered scrollable no-close-on-backdrop
           v-model="visible">

    <template #modal-header>
      <div class="fw-bold">{{ $t('plan.businessPlanTitle') }} {{ $t('common.edit') }}</div>
    </template>

    <div class="row">
      <div class="col-xl-12">

        <div class="row mb-3">
          <div class="col-xl-6">
            <label class="form-label" for="business-plan-update-name">{{ $t('plan.planName') }}</label>
            <b-form-input class="form-control" type="text" id="business-plan-update-name" v-model="form.planName" disabled />
          </div>
          <div class="col-xl-6"></div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-12">
            <label class="form-label" for="business-plan-update-desc">
              <span>{{ $t('plan.planDescription') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="business-plan-update-desc"
                          v-model="form.planDescription"
                          :class="{ 'is-invalid': submitted && $v.form.planDescription.$error }" />
            <div v-if="submitted && $v.form.planDescription.$error" class="invalid-feedback">
              <span v-if="!$v.form.planDescription.required">{{ $t('validation.planDescIsRequired') }}</span>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-4">
            <label class="form-label" for="business-plan-update-server">
              <span>{{ $t('plan.serverUsageFee') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="business-plan-update-server"
                          v-model="form.serverUsageFee"
                          :class="{ 'is-invalid': submitted && $v.form.serverUsageFee.$error }" />
            <div v-if="submitted && $v.form.serverUsageFee.$error" class="invalid-feedback">
              <span v-if="!$v.form.serverUsageFee.required">{{ $t('validation.serverUsageFeeIsRequired') }}</span>
            </div>
          </div>
          <div class="col-xl-4">
            <label class="form-label" for="business-plan-update-settle">
              <span>{{ $t('plan.settleRate') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="business-plan-update-settle"
                          v-model="form.settleRate"
                          :class="{ 'is-invalid': submitted && $v.form.settleRate.$error }" />
            <div v-if="submitted && $v.form.settleRate.$error" class="invalid-feedback">
              <span v-if="!$v.form.settleRate.required">{{ $t('validation.settleRateIsRequired') }}</span>
            </div>
          </div>
          <div class="col-xl-4" v-if="planId === 10000000000225">
            <label class="form-label" for="business-plan-update-profit">
              <span>{{ $t('plan.profitDistRate') }}</span>
              <i class="uil uil-asterisk text-danger ms-1"></i>
            </label>
            <b-form-input class="form-control"
                          type="text"
                          id="business-plan-update-profit"
                          v-model="form.profitDistRate"
                          :class="{ 'is-invalid': submitted && $v.form.profitDistRate.$error }" />
            <div v-if="submitted && $v.form.profitDistRate.$error" class="invalid-feedback">
              <span v-if="!$v.form.profitDistRate.required">{{ $t('validation.profitDistRateIsRequired') }}</span>
            </div>
          </div>
        </div>

      </div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-end">
        <b-button variant="secondary" class="w-xs p-1 me-3" @click="close">{{ $t('btn.close') }}</b-button>
        <b-button variant="success" class="w-xs p-1" @click="save">{{ $t('btn.save') }}</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import { http } from '@/auth-api';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    selectedId: {
      type: Number
    }
  },
  data() {
    return {
      planId: null,
      visible: false,
      submitted: false,
      form: {
        planName: null,
        planDescription: null,
        serverUsageFee: null,
        settleRate: null,
        profitDistRate: null
      }
    }
  },
  validations: {
    form: {
      planDescription: { required },
      serverUsageFee: { required },
      settleRate: { required },
      profitDistRate: { required }
    }
  },
  methods: {
    show() {
      const vm = this;

      vm.planId = this.selectedId;
      vm.getItem();
      vm.visible = true;
    },
    getItem() {
      const vm = this;
      const _url = `/onmapi/view/ChargePlacePlan/retrieve/${this.selectedId}`;

      http.get(_url).then((res) => {
        vm.item = res.data.item;
        vm.form = Object.assign({}, vm.item);
      }).catch((err) => {
        console.error('Business Plan Retrieve Error !!', err)
      });
    },
    close() {
      const vm = this;

      this.$emit('callback');
      vm.resetForm();
      vm.visible = false;
    },
    resetForm() {
      const vm = this;

      vm.form = {
        planName: null,
        planDescription: null,
        serverUsageFee: null,
        settleRate: null,
        profitDistRate: null
      }

      vm.$nextTick(() => {
        this.$v.$reset();
      });
    },
    save() {
      const vm = this;
      vm.submitted = true;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        Swal.fire({
          title: i18n.t('alert.confirmEdit'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            vm.saveItems();
          }
        });
      }
    },
    saveItems() {
      const vm = this;
      const _url = '/onmapi/view/ChargePlacePlan/update';

      let _form = { ...vm.form, id: this.selectedId };

      http.patch(_url, _form).then(res => {
        if (res) {
          Swal.fire({
            icon: 'info',
            title: i18n.t('alert.edited'),
            confirmButtonText: i18n.t('btn.close')
          });
          vm.close();
        }
      }).catch(err => {
        console.error('Business Plan Edit Error :: ', err)

        Swal.fire({
          icon: 'error',
          title: i18n.t('alert.error'),
          confirmButtonText: i18n.t('btn.close')
        });
      });
    }
  }
}
</script>
